import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 207 120"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Chat"
      className="cls-1"
      fillRule="evenodd"
      d="M203.331,120H191.3a9.652,9.652,0,0,1-8.045-4.333l-5.722-8.64H119.706l-5.723,8.64A9.65,9.65,0,0,1,105.939,120H93.909a3.243,3.243,0,0,1-2.681-5.04L106.485,91.9a9.642,9.642,0,0,1,8.04-4.332h68.193a10.227,10.227,0,0,1,8.036,4.332l15.258,23.061A3.243,3.243,0,0,1,203.331,120Zm-54.7-42.162C125.165,77.838,110,62.559,110,38.919S125.165,0,148.632,0s38.634,15.279,38.634,38.919S172.1,77.838,148.632,77.838Zm0-58.38c-12.818,0-19.318,6.548-19.318,19.461s6.5,19.46,19.318,19.46,19.318-6.547,19.318-19.46S161.451,19.458,148.632,19.458ZM58.486,77.838c-23.467,0-38.634-15.279-38.634-38.919S35.019,0,58.486,0,97.12,15.279,97.12,38.919,81.953,77.838,58.486,77.838Zm0-58.38c-12.818,0-19.318,6.548-19.318,19.461s6.5,19.46,19.318,19.46S77.8,51.832,77.8,38.919,71.3,19.458,58.486,19.458ZM90.011,93.365L83.834,102.7a9.638,9.638,0,0,1-8.035,4.331H29.56l-5.722,8.639A9.652,9.652,0,0,1,15.793,120H3.761a3.243,3.243,0,0,1-2.679-5.04L16.34,91.9a9.638,9.638,0,0,1,8.035-4.331H86.93A3.73,3.73,0,0,1,90.011,93.365Z"
    />
  </svg>
))
