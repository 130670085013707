import React, { FC } from "react"
import { Props, IconProps } from "./types"
import * as styles from "./styles.module.scss"
import Project from "src/components/Icons/Projects"
import ToDo from "src/components/Icons/Task"
import Calendar from "src/components/Icons/Calendar"
import Productivity from "src/components/Icons/Powerful"
import Chat from "src/components/Icons/Chat"
import Kanban from "src/components/Icons/KanbanBoard"
import Task from "src/components/Icons/TaskManagement"
import Team from "src/components/Icons/Team"
import Organized from "src/components/Icons/Organized"
import Cloud from "src/components/Icons/Cloud"

const Icon: FC<IconProps> = ({ icon }) => {
  // noinspection JSRedundantSwitchStatement
  switch (icon) {
    case "Project management":
      return <Project className={styles.logo} aria-hidden="true" />
    case "Kanban boards":
      return <Kanban className={styles.logo} aria-hidden="true" />
    case "Task management":
      return <Task className={styles.logo} aria-hidden="true" />
    case "To-do lists":
      return <ToDo className={styles.logo} aria-hidden="true" />
    case "Team calendars":
      return <Calendar className={styles.logo} aria-hidden="true" />
    case "Team collaboration":
      return <Team className={styles.logo} aria-hidden="true" />
    case "Team communication":
      return <Chat className={styles.logo} aria-hidden="true" />
    case "Getting organized":
      return <Organized className={styles.logo} aria-hidden="true" />
    case "Remote work":
      return <Cloud className={styles.logo} aria-hidden="true" />
    default:
      return <Productivity className={styles.logo} aria-hidden="true" />
  }
}

const UseCard: FC<Props> = ({ description, title, variant, className }) => {
  return (
    <div
      className={`${styles.card} ${
        variant === "featured" ? styles.featured : ""
      } ${className}`}
    >
      <div className={styles.header}>
        <Icon icon={title} />
      </div>
      <div className={styles.body}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  )
}

export default UseCard
