import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 120 120"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Chat"
      className="cls-1"
      fillRule="evenodd"
      d="M115.909,120H69.546a4.091,4.091,0,0,1-4.091-4.091V69.545a4.091,4.091,0,0,1,4.091-4.091h46.364A4.091,4.091,0,0,1,120,69.545v46.364A4.091,4.091,0,0,1,115.909,120ZM103.636,81.818H81.818v21.818h21.818V81.818Zm12.273-27.273H69.546a4.091,4.091,0,0,1-4.091-4.091V4.091A4.091,4.091,0,0,1,69.546,0h46.364A4.091,4.091,0,0,1,120,4.091V50.455A4.091,4.091,0,0,1,115.909,54.545ZM103.636,16.363H81.818V38.182h21.818V16.363ZM50.455,120H4.091A4.091,4.091,0,0,1,0,115.909V69.545a4.091,4.091,0,0,1,4.091-4.091H50.455a4.091,4.091,0,0,1,4.091,4.091v46.364A4.091,4.091,0,0,1,50.455,120ZM38.182,81.818H16.364v21.818H38.182V81.818ZM50.455,54.545H4.091A4.091,4.091,0,0,1,0,50.455V4.091A4.091,4.091,0,0,1,4.091,0H50.455a4.091,4.091,0,0,1,4.091,4.091V50.455A4.091,4.091,0,0,1,50.455,54.545ZM38.182,16.363H16.364V38.182H38.182V16.363Z"
    />
  </svg>
))
