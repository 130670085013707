import { graphql } from "gatsby"
import React, { FC } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { usePages } from "src/context/pages"
import Logo from "src/images/structured-data-logo.png"
import * as styles from "./styles.module.scss"
import UseCard from "src/components/UseCard"
import Link from "src/utils/Link"
import GetStarted from "src/components/Banners/GetStarted"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { currentPath, getPage } = usePages()

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
    >
      <div className={`content-wrapper ${styles.container}`}>
        <header className={styles.header}>
          <h1 className={styles.title}>{texts.header}</h1>
          <p className={styles.description}>{texts.description}</p>
          <Link
            to={getPage("signup").url}
            className={`button-new button-new--primary`}
          >
            {texts.button}
          </Link>
        </header>
        <div className={styles.uses}>
          {texts.uses.map(use => {
            return <UseCard key={use.title} {...use} />
          })}
        </div>
        <section className={styles.section}>
          <div className={styles.header}>
            <h2 className={styles.title}>{texts.headerSection}</h2>
            <p className={styles.description}>{texts.descriptionSection}</p>
          </div>
          <div className={styles.uses}>
            {texts.usesSection.map(use => {
              return <UseCard variant="featured" key={use.title} {...use} />
            })}
          </div>
        </section>
        <GetStarted
          className={styles.banner}
          description={texts.banner}
          variant="uses"
        />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        description
        button
        uses {
          title
          description
        }
        headerSection
        descriptionSection
        usesSection {
          title
          description
        }
        banner
      }
    }
  }
`

export default Template
