import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56 40"
    {...props}
  >
    {children}
    <defs />
    <path
      fill-rule="evenodd"
      d="M26 6c5.959 0 10.019 2.732 11.431 7.692l.87 3.056a1.5 1.5 0 001.347 1.086l3.171.2C48.754 18.415 50 22.576 50 26c0 5.159-2.841 8-8 8H12c-3.982 0-6-2.019-6-6 0-3.093 1.241-5.012 3.69-5.7l3.075-.868a1.5 1.5 0 001.09-1.352l.194-3.189C14.471 9.968 18.827 6 26 6m0-6C15.57 0 8.675 6.44 8.06 16.523 3.061 17.934 0 22.128 0 28c0 7.289 4.711 12 12 12h30c8.5 0 14-5.5 14-14 0-8.094-4.985-13.452-12.8-13.951C41.074 4.575 34.792 0 26 0z"
    />
  </svg>
))
