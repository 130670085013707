import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 120 120"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Chat"
      className="cls-1"
      fillRule="evenodd"
      d="M67.678,86.661A7.489,7.489,0,0,1,61.445,90H58.507a7.489,7.489,0,0,1-6.233-3.339L25.447,46.39A2.5,2.5,0,0,1,27.527,42.5h9.345a7.513,7.513,0,0,1,6.249,3.34l16.856,25.3,41.838-62.8A7.514,7.514,0,0,1,108.063,5h9.345a2.5,2.5,0,0,1,2.082,3.885ZM119.951,60c0,36.445-23.555,60-60,60s-60-23.555-60-60S23.5,0,59.95,0A67.981,67.981,0,0,1,87.322,5.353,3.145,3.145,0,0,1,88.7,9.978L84.044,16.95a3.7,3.7,0,0,1-4.385,1.442A54.342,54.342,0,0,0,59.95,15c-28.179,0-45,16.822-45,45s16.822,45,45,45,45-16.821,45-45a64.172,64.172,0,0,0-.432-7.547,7.311,7.311,0,0,1,1.221-4.9l5.764-8.639a3.165,3.165,0,0,1,5.676.928A71.414,71.414,0,0,1,119.951,60Z"
    />
  </svg>
))
