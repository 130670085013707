import React from "react"
import accessibleSvg from "src/utils/accessibleSvg"

export default accessibleSvg(({ children, ...props }) => (
  <svg
    fill="currentColor"
    viewBox="0 0 138 112"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {children}
    <path
      id="Chat"
      className="cls-1"
      fillRule="evenodd"
      d="M127.385,112H10.615A10.641,10.641,0,0,1,0,101.333V60.844A16.056,16.056,0,0,1,2.674,51.97L34.79,3.562A7.951,7.951,0,0,1,41.415,0H96.586a7.952,7.952,0,0,1,6.624,3.562l32.116,48.407A16.054,16.054,0,0,1,138,60.844v40.489A10.641,10.641,0,0,1,127.385,112ZM53.077,53.333h0c0,9.718,6.25,16,15.922,16s15.921-6.28,15.921-16h0V52A3.99,3.99,0,0,1,88.9,48h24.651L92.325,16H45.676L24.445,48H49.1a3.99,3.99,0,0,1,3.981,4v1.333Zm69,10.667H99.4C95.616,77.235,84.519,85.334,69,85.334S42.384,77.235,38.6,64H15.923V92A3.991,3.991,0,0,0,19.9,96H118.1a3.99,3.99,0,0,0,3.981-4V64Z"
    />
  </svg>
))
